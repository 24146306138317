.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4;
  margin: 0;
  font-family: Arial, sans-serif;
}

h1 {
  color: #333;
  font-size: 2.5rem;
  text-align: center;
}
